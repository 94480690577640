const listCountries = [
    { "codpais": "001", "descpais": "Venezuela" },
    { "codpais": "002", "descpais": "Afganistan" },
    { "codpais": "003", "descpais": "Albania" },
    { "codpais": "004", "descpais": "Alemania" },
    { "codpais": "005", "descpais": "Andorra" },
    { "codpais": "006", "descpais": "Angola" },
    { "codpais": "007", "descpais": "Anguila" },
    { "codpais": "008", "descpais": "Antigua y Barbuda" },
    { "codpais": "009", "descpais": "Antillas Holandesas" },
    { "codpais": "010", "descpais": "Aotearoa-Cook" },
    { "codpais": "011", "descpais": "Aotearoa-Niue" },
    { "codpais": "012", "descpais": "Arabia saudita" },
    { "codpais": "013", "descpais": "Argelia" },
    { "codpais": "014", "descpais": "Argentina" },
    { "codpais": "015", "descpais": "Armenia" },
    { "codpais": "016", "descpais": "Aruba" },
    { "codpais": "017", "descpais": "Ascension" },
    { "codpais": "018", "descpais": "Australia" },
    { "codpais": "019", "descpais": "Austria" },
    { "codpais": "020", "descpais": "Azerbaiyan" },
    { "codpais": "021", "descpais": "Bahamas" },
    { "codpais": "022", "descpais": "Bahrain" },
    { "codpais": "023", "descpais": "Bahrein" },
    { "codpais": "024", "descpais": "Bangladesh" },
    { "codpais": "025", "descpais": "Barbados" },
    { "codpais": "027", "descpais": "Belice" },
    { "codpais": "028", "descpais": "Benin" },
    { "codpais": "029", "descpais": "Bermudas" },
    { "codpais": "030", "descpais": "Bhutan" },
    { "codpais": "031", "descpais": "Bielorrusia" },
    { "codpais": "032", "descpais": "Bolivia" },
    { "codpais": "033", "descpais": "Bosnia y Herzegovina" },
    { "codpais": "034", "descpais": "Botsuana" },
    { "codpais": "035", "descpais": "Botswana" },
    { "codpais": "036", "descpais": "Brasil" },
    { "codpais": "037", "descpais": "Brunei" },
    { "codpais": "038", "descpais": "Bulgaria" }, 
    { "codpais": "039", "descpais": "Burkina Faso" },
    { "codpais": "040", "descpais": "Burundi" }, 
    { "codpais": "026", "descpais": "Belgica" },
    { "codpais": "041", "descpais": "Camboya" }, 
    { "codpais": "042", "descpais": "Camerún" },
    { "codpais": "043", "descpais": "Canada" }, 
    { "codpais": "044", "descpais": "Chad" },
    { "codpais": "045", "descpais": "Chile" }, 
    { "codpais": "046", "descpais": "CHINA" },
    { "codpais": "047", "descpais": "Chipre" }, 
    { "codpais": "048", "descpais": "Chipre Turco" },
    { "codpais": "049", "descpais": "Ciudad del Vaticano" },
    { "codpais": "050", "descpais": "Colombia" },
    { "codpais": "051", "descpais": "Comoras" },
    { "codpais": "052", "descpais": "Comoros" },
    { "codpais": "053", "descpais": "Congo - Zaire" },
    { "codpais": "054", "descpais": "Congo, r." },
    { "codpais": "055", "descpais": "Corea del Norte" },
    { "codpais": "056", "descpais": "Corea del Sur" },
    { "codpais": "057", "descpais": "Costa de Marfil" },
    { "codpais": "058", "descpais": "Costa Rica" },
    { "codpais": "060", "descpais": "Croacia" },
    { "codpais": "061", "descpais": "Cuba" },
    { "codpais": "252", "descpais": "Curazao" },
    { "codpais": "059", "descpais": "Cote D'Ivoire" },
    { "codpais": "062", "descpais": "Dinamarca" },
    { "codpais": "063", "descpais": "Dinamarca-Faeroe" },
    { "codpais": "064", "descpais": "Dinamarca-Groenlandia" },
    { "codpais": "065", "descpais": "Djibouti" },
    { "codpais": "066", "descpais": "Ecuador" },
    { "codpais": "067", "descpais": "Egipto" },
    { "codpais": "068", "descpais": "El Salvador" },
    { "codpais": "069", "descpais": "Emiratos Arabes UnidoS" },
    { "codpais": "070", "descpais": "Eritrea" },
    { "codpais": "071", "descpais": "Escocia" },
    { "codpais": "072", "descpais": "Eslovaquia" },
    { "codpais": "073", "descpais": "Eslovenia" },
    { "codpais": "074", "descpais": "España" },
    { "codpais": "075", "descpais": "españa-Gibraltar" },
    { "codpais": "076", "descpais": "Estados Unidos de America" },
    { "codpais": "077", "descpais": "Estonia" },
    { "codpais": "078", "descpais": "Etiopía" },
    { "codpais": "079", "descpais": "Fiji" },
    { "codpais": "080", "descpais": "Filipinas" },
    { "codpais": "081", "descpais": "Finlandia" },
    { "codpais": "082", "descpais": "Francia" },
    { "codpais": "083", "descpais": "Francia-San Pedro Y Miquelon" },
    { "codpais": "084", "descpais": "Francia-Wallis Y Futuna" },
    { "codpais": "085", "descpais": "Gabón" },
    { "codpais": "086", "descpais": "Gambia" },
    { "codpais": "087", "descpais": "Georgia" },
    { "codpais": "088", "descpais": "Ghana" },
    { "codpais": "089", "descpais": "Granada" },
    { "codpais": "090", "descpais": "Grecia" },
    { "codpais": "091", "descpais": "Guadalupe" },
    { "codpais": "092", "descpais": "Guam" },
    { "codpais": "093", "descpais": "Guatemala" },
    { "codpais": "094", "descpais": "Guayana Francesa" },
    { "codpais": "095", "descpais": "Guerney" },
    { "codpais": "096", "descpais": "Guinea" },
    { "codpais": "097", "descpais": "Guinea Bissau" },
    { "codpais": "098", "descpais": "Guinea Ecuatorial" },
    { "codpais": "099", "descpais": "Guyana" },
    { "codpais": "100", "descpais": "Haití" },
    { "codpais": "101", "descpais": "Holanda" },
    { "codpais": "102", "descpais": "Honduras" },
    { "codpais": "103", "descpais": "Hungría" },
    { "codpais": "104", "descpais": "India" },
    { "codpais": "105", "descpais": "Indonesia" },
    { "codpais": "106", "descpais": "Inglaterra" },
    { "codpais": "107", "descpais": "Irak" },
    { "codpais": "109", "descpais": "Irlanda del Norte" },
    { "codpais": "110", "descpais": "Irlanda del Sur" },
    { "codpais": "108", "descpais": "Irán" },
    { "codpais": "111", "descpais": "Isla Cabo Verde" },
    { "codpais": "112", "descpais": "Isla Caimán" },
    { "codpais": "113", "descpais": "Islandia" },
    { "codpais": "114", "descpais": "Islas Cocos" },
    { "codpais": "115", "descpais": "Islas Cook" },
    { "codpais": "116", "descpais": "Islas Faroe" },
    { "codpais": "117", "descpais": "Islas Malvinas" },
    { "codpais": "118", "descpais": "Islas Marianas" },
    { "codpais": "119", "descpais": "Islas Marshall" },
    { "codpais": "120", "descpais": "Islas Salomón" },
    { "codpais": "121", "descpais": "Islas Virgenes Americanas" },
    { "codpais": "122", "descpais": "Islas Virgenes Britanicas" },
    { "codpais": "123", "descpais": "Israel" },
    { "codpais": "124", "descpais": "Italia" },
    { "codpais": "125", "descpais": "Jamaica" },
    { "codpais": "126", "descpais": "Japón" },
    { "codpais": "127", "descpais": "Jersey" },
    { "codpais": "128", "descpais": "Jordan" },
    { "codpais": "129", "descpais": "Jordania" },
    { "codpais": "130", "descpais": "Kanaky" },
    { "codpais": "131", "descpais": "Kazajstán" },
    { "codpais": "132", "descpais": "Kenia" },
    { "codpais": "133", "descpais": "Kirguistãn" },
    { "codpais": "134", "descpais": "Kiribati" },
    { "codpais": "135", "descpais": "Kuwait" },
    { "codpais": "136", "descpais": "Laos" },
    { "codpais": "137", "descpais": "Letonia" },
    { "codpais": "139", "descpais": "Liberia" },
    { "codpais": "140", "descpais": "Libia" },
    { "codpais": "141", "descpais": "Liechtenstein" },
    { "codpais": "142", "descpais": "Lituania" },
    { "codpais": "143", "descpais": "Luxemburgo" },
    { "codpais": "138", "descpais": "Líbano" },
    { "codpais": "144", "descpais": "Macao" },
    { "codpais": "145", "descpais": "Macedonia" },
    { "codpais": "146", "descpais": "Madagascar" },
    { "codpais": "147", "descpais": "Malasia" },
    { "codpais": "148", "descpais": "Malawi" },
    { "codpais": "149", "descpais": "Maldivas" },
    { "codpais": "151", "descpais": "Malta" },
    { "codpais": "150", "descpais": "Malí" },
    { "codpais": "152", "descpais": "Marianas del norte" },
    { "codpais": "153", "descpais": "Marruecos" },
    { "codpais": "154", "descpais": "Marshall" },
    { "codpais": "155", "descpais": "Martinica" },
    { "codpais": "156", "descpais": "Mauricio" },
    { "codpais": "157", "descpais": "Mauritania" },
    { "codpais": "158", "descpais": "Mayotte" },
    { "codpais": "160", "descpais": "Micronesia" },
    { "codpais": "161", "descpais": "Moldova" },
    { "codpais": "163", "descpais": "Mongolia" },
    { "codpais": "164", "descpais": "Montserrat" },
    { "codpais": "165", "descpais": "Morocco" },
    { "codpais": "166", "descpais": "Mozambique" },
    { "codpais": "167", "descpais": "Myanmar" },
    { "codpais": "159", "descpais": "Mexico" },
    { "codpais": "162", "descpais": "Mónaco" },
    { "codpais": "168", "descpais": "Namibia" },
    { "codpais": "169", "descpais": "Naurú" },
    { "codpais": "170", "descpais": "Nepal" },
    { "codpais": "171", "descpais": "Nicaragua" },
    { "codpais": "173", "descpais": "Nigeria" },
    { "codpais": "174", "descpais": "Niue" },
    { "codpais": "175", "descpais": "Norfolk Islands" },
    { "codpais": "176", "descpais": "Noruega" },
    { "codpais": "177", "descpais": "Nueva Caledonia" },
    { "codpais": "178", "descpais": "Nueva Zelanda" },
    { "codpais": "172", "descpais": "Níger" },
    { "codpais": "179", "descpais": "Omán" },
    { "codpais": "181", "descpais": "Pakistán" },
    { "codpais": "182", "descpais": "Palau" },
    { "codpais": "183", "descpais": "Palestina" },
    { "codpais": "184", "descpais": "Panamá" },
    { "codpais": "185", "descpais": "Papua-Nueva Guinea" },
    { "codpais": "186", "descpais": "Paraguay" },
    { "codpais": "180", "descpais": "Países Bajos" },
    { "codpais": "187", "descpais": "Perú" },
    { "codpais": "188", "descpais": "Pitcairn" },
    { "codpais": "189", "descpais": "Polinesia Francesa" },
    { "codpais": "190", "descpais": "Polonia" },
    { "codpais": "191", "descpais": "Portugal" },
    { "codpais": "192", "descpais": "Puerto Rico" },
    { "codpais": "193", "descpais": "Qatar" },
    { "codpais": "194", "descpais": "Reino Unido" },
    { "codpais": "197", "descpais": "República Dominica" },
    { "codpais": "195", "descpais": "República Centroafricana" },
    { "codpais": "196", "descpais": "República Checa" },
    { "codpais": "198", "descpais": "Reunión" },
    { "codpais": "199", "descpais": "Ruanda" },
    { "codpais": "200", "descpais": "Rumania" },
    { "codpais": "201", "descpais": "Rusia" },
    { "codpais": "202", "descpais": "Sahara" },
    { "codpais": "203", "descpais": "Salomón" },
    { "codpais": "204", "descpais": "Samoa Americana" },
    { "codpais": "205", "descpais": "Samoa Occidental" },
    { "codpais": "206", "descpais": "San Cristobal y Nevis" },
    { "codpais": "207", "descpais": "San Marino" },
    { "codpais": "208", "descpais": "San Tomé y Príncipe" },
    { "codpais": "209", "descpais": "San vicente" },
    { "codpais": "210", "descpais": "Santa helena" },
    { "codpais": "211", "descpais": "Santa lucía" },
    { "codpais": "212", "descpais": "Senegal" },
    { "codpais": "213", "descpais": "Servia de Montenegro" },
    { "codpais": "214", "descpais": "Seychelles" },
    { "codpais": "215", "descpais": "Sierra Leona" },
    { "codpais": "216", "descpais": "Singapur" },
    { "codpais": "217", "descpais": "Siria" },
    { "codpais": "218", "descpais": "Somalia" },
    { "codpais": "219", "descpais": "Sri Lanka" },
    { "codpais": "220", "descpais": "Sudáfrica" },
    { "codpais": "221", "descpais": "Sudán" },
    { "codpais": "222", "descpais": "Suecia" },
    { "codpais": "223", "descpais": "Suiza" },
    { "codpais": "224", "descpais": "Surinam" },
    { "codpais": "225", "descpais": "Swazilandia" },
    { "codpais": "226", "descpais": "Tailandia" },
    { "codpais": "227", "descpais": "Taiwan" },
    { "codpais": "228", "descpais": "Tanzania" },
    { "codpais": "229", "descpais": "Tayikistán" },
    { "codpais": "230", "descpais": "Tibet" },
    { "codpais": "231", "descpais": "Timor Oriental" },
    { "codpais": "232", "descpais": "Togo" },
    { "codpais": "233", "descpais": "Tokelau" },
    { "codpais": "234", "descpais": "Tonga" },
    { "codpais": "235", "descpais": "Trinidad y Tobago" },
    { "codpais": "237", "descpais": "Turcas y Caicos" },
    { "codpais": "238", "descpais": "Turkmenistán" },
    { "codpais": "239", "descpais": "Turquía" },
    { "codpais": "240", "descpais": "Tuvalu" },
    { "codpais": "236", "descpais": "Túnez" },
    { "codpais": "241", "descpais": "Ucrania" },
    { "codpais": "242", "descpais": "Uganda" },
    { "codpais": "243", "descpais": "Uruguay" },
    { "codpais": "244", "descpais": "Uzbekistán" },
    { "codpais": "245", "descpais": "Vanuatu" },
    { "codpais": "246", "descpais": "vietnam" },
    { "codpais": "248", "descpais": "Yibuti" },
    { "codpais": "249", "descpais": "Yugoslavia" },
    { "codpais": "247", "descpais": "Yémen" },
    { "codpais": "250", "descpais": "Zambia" },
    { "codpais": "251", "descpais": "Zimbabwe" }
];

export {
    listCountries
}